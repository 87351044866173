<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
     <a-card :bordered="false">
       <a-form-model ref="form" :model="form" style="width: 80%">
         <a-form-model-item label="用户编号" prop="customerId">
           <a-input :disabled="true" v-model="form.customerId" @keyup.enter="initCustomerInfo"/>
         </a-form-model-item>
         <a-form-model-item label="用户姓名" prop="customerName">
           <a-input :disabled="true"  v-model="form.customerName" />
         </a-form-model-item>
         <a-form-model-item label="性别" prop="sex">
           <a-select :disabled="true" v-model="form.sex">
             <a-select-option :value="1">男</a-select-option>
             <a-select-option :value="2">女</a-select-option>
           </a-select>
         </a-form-model-item>
         <a-form-model-item label="年龄" prop="age">
           <a-input :disabled="true" v-model="form.age"/>
         </a-form-model-item>
         <a-form-model-item label="手机号" prop="mobile">
           <a-input :disabled="true" v-model="form.mobile"/>
         </a-form-model-item>
         <a-form-model-item label="设置游戏" prop="gameList">
            <span style="margin-left: 10px" v-for="(item,key) in form.gameSelectList" :key="key" :value="item.id">{{item.gameName}}</span>
         </a-form-model-item>
       </a-form-model>
     </a-card>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          保存
        </a-button>
        <a-button type="dashed" @click="cancel">
          取消
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import { getOrder, addOrder, updateOrder,confirmOrder } from '@/api/order/order'

export default {
  name: 'SellInfo',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '确定设置所选游戏',
      // 表单参数
      form: {
        customerId: null,
        customerName: null,
        sex: null,
        age: null,
        mobile: null,
        gameSelectList:[],
      },
      open: false,
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        customerId: null,
        customerName: null,
        sex: null,
        age: null,
        mobile: null,
        gameSelectList:[],
      }
    },
    confirmSelect(orderInfo){
      this.reset()
      confirmOrder(orderInfo).then(res=>{
        this.form = res.data
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      addOrder(this.form).then(response => {
        this.$message.success(
          '操作成功',
          3
        )
        this.open = false
        this.$emit('ok')
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
