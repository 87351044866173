<template>
  <a-modal width="50%" title="预览" :visible="open"  @cancel="onClose">
    <div id="image" style="text-align: center">
      <img  type="example" :src="image" style="width: 500px; height: 500px">
    </div>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary"  @click="handlePrint('image')">
            打印
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>

  </a-modal>
</template>

<script>
import { getInfo, addInfo, updateInfo } from '@/api/customer/info'
import print from 'print-js'

export default {
  name: 'ImagePreview',
  props: {
  },
  components: {
  },
  data () {
    return {
      image:null,
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    openPreview(image){
      this.open = true;
      this.image = image;
    },
    // 表单重置
    reset () {
      this.image = null
    },

    handlePrint (table) {
      let userAgent = navigator.userAgent;
      //判断是否Firefox浏览器
      if (userAgent.indexOf("Firefox") > -1) {
        console.log('Firefox')
        print({
          printable: table, // 标签元素id
          type: 'html',
          header: '',
          style: `@page {size:auto;margin-top:100px; margin-left:15px; margin-right: 15px;}
            thead th {
              border-top: 1px solid #000;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            }
            tbody td {
              border: 1px solid #000;
            }
            tbody {
              text-align: center;
            }
            table {
              border-collapse: collapse;
            }`,
        });
      }
      //判断是否chorme浏览器
      if (userAgent.indexOf("Chrome") > -1){
        console.log('Chrome')
        print({
          printable: table, // 标签元素id
          type: 'html',
          header: '',
          documentTitle: '',
          style: `@page {size:auto;margin-top:100px; margin-left:5px; margin-right: 15px;}
            thead th {
              border-top: 1px solid #000;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            }
            tbody td {
              border: 1px solid #000;
              font-size: 12px;
            }
            tbody {
              text-align: center;
            }
            table {
              border-collapse: collapse;
            }`,
        });
      }
      //判断是否IE浏览器
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        console.log('IE')
        print({
          printable: table, // 标签元素id
          type: 'html',
          header: '',
          style: `@page {size:auto;margin-top:100px; margin-left:15px; margin-right: 15px;}
            thead th {
              border-top: 1px solid #000;
              border-right: 1px solid #000;
              border-left: 1px solid #000;
            }
            tbody td {
              border: 1px solid #000;
            }
            tbody {
              text-align: center;
            }
            table {
              border-collapse: collapse;
            }`,
        });
      }
    },
  }
}
</script>
