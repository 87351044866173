import request from '@/utils/request'


// 查询订单管理信息列表
export function listOrder(query) {
  return request({
    url: '/order/order/list',
    method: 'get',
    params: query
  })
}

// 查询订单管理信息分页
export function pageOrder(query) {
  return request({
    url: '/order/order/page',
    method: 'get',
    params: query
  })
}

// 查询订单管理信息详细
export function getOrder(data) {
  return request({
    url: '/order/order/detail',
    method: 'get',
    params: data
  })
}

// 新增订单管理信息
export function addOrder(data) {
  return request({
    url: '/order/order/add',
    method: 'post',
    data: data
  })
}

export function orderInfo(customerId) {
  return request({
    url: '/order/order/info/'+customerId,
    method: 'get',
  })
}

export function startGame(id) {
  return request({
    url: '/order/item/startGame/'+id,
    method: 'post',
  })
}

export function uploadResult (data) {
  return request({
    url: '/order/item/uploadResult',
    method: 'post',
    data: data
  })
}


export function grabResult(id) {
  return request({
    url: '/order/item/grabResult/'+id,
    method: 'post',
  })
}

export function confirmOrder(data){
  return request({
    url: '/order/order/confirm',
    method: 'post',
    data: data
  })
}

// 修改订单管理信息
export function updateOrder(data) {
  return request({
    url: '/order/order/edit',
    method: 'post',
    data: data
  })
}

// 删除订单管理信息
export function delOrder(data) {
  return request({
    url: '/order/order/delete',
    method: 'post',
    data: data
  })
}
