<template>
  <page-header-wrapper>
    <a-card :bordered="false" style="width: 80%">
      <a-form-model ref="form" :model="form" style="width: 50%">
      <a-form-model-item label="用户编号" prop="customerId">
        <a-input :readOnly="isReset" v-model="form.customerId" @keyup.enter="initCustomerInfo"/>
      </a-form-model-item>
      <a-form-model-item label="用户姓名" prop="customerName">
        <a-input :readOnly="isReset"  v-model="form.customerName" />
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-select :disabled="isReset" v-model="form.sex">
          <a-select-option :value="0">未知</a-select-option>
          <a-select-option :value="1">男</a-select-option>
          <a-select-option :value="2">女</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="年龄" prop="age">
        <a-input :readOnly="isReset" v-model="form.age"/>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile">
        <a-input :readOnly="isReset" v-model="form.mobile"/>
      </a-form-model-item>
      <a-form-model-item label="游戏项目" prop="gameList">
        <a-checkbox-group v-model="form.gameList">
          <a-checkbox v-for="(item,key) in gameList" :key="key" :value="item.id">{{item.gameName}}</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
      <sell-info
        ref="sellInfo"
        @ok="cancel"
      />
      <image-preview
      ref="preview"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageOrder,listOrder, delOrder } from '@/api/order/order'
import { tableMixin } from '@/store/table-mixin'
import {listGame} from "@/api/game/info";
import SellInfo from "./modules/sellInfo"
import {getInfo} from "@/api/customer/info";
import ImagePreview from './modules/preview'

export default {
  name: 'Sell',
  components: {ImagePreview, SellInfo},
  mixins: [tableMixin],
  data () {
    return {
      gameList:[],
      isReset:false,
      form:{
        customerId: null,
        customerName: null,
        sex: null,
        age: null,
        mobile: null,
        gameList:[],
      },
    }
  },
  filters: {
  },
  created () {
    this.initGameList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    initGameList(){
      listGame({isDeleted : 0}).then(res=>{
        this.gameList = res.data
        console.log("游戏列表",this.gameList)
      })
    },
    cancel(){
      getInfo({id : this.form.customerId}).then(res=>{
        this.reset()
        this.isReset = false;
        this.$refs.preview.openPreview(res.data.image)
      })
    },
    initCustomerInfo(){
      if(this.form.customerId){
        console.log("url",this.form.customerId)
        let url = this.form.customerId+'';
        let id = url.split("=")[1]
        this.reset()
        getInfo({id:id}).then(res=>{
          this.isReset=true
          this.form = res.data
          this.form.customerId = id
          console.log("用户信息",this.form)
        })
      }
    },
    submit(){
      if(!this.form.customerId){
        this.$message.error("请扫描用户二维码")
        return
      }
      if(this.form.gameList.length==0){
        this.$message.error("请选择游戏项目")
        return
      }
      this.$refs.sellInfo.confirmSelect(this.form)
    },
    reset(){
      this.form={
          customerId: null,
          customerName: null,
          sex: null,
          age: null,
          mobile: null,
          gameList: [],
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
  }
}
</script>
